@font-face {font-family: 'Gilroy'; font-weight: 400; src: url('../assets/webfonts/3AA3AA_0_0.eot');src: url('../assets/webfonts/3AA3AA_0_0.eot?#iefix') format('embedded-opentype'),url('../assets/webfonts/3AA3AA_0_0.woff2') format('woff2'),url('../assets/webfonts/3AA3AA_0_0.woff') format('woff'),url('../assets/webfonts/3AA3AA_0_0.ttf') format('truetype');}
@font-face {font-family: 'Gilroy'; font-weight: 900; src: url('../assets/webfonts/3AA3AA_1_0.eot');src: url('../assets/webfonts/3AA3AA_1_0.eot?#iefix') format('embedded-opentype'),url('../assets/webfonts/3AA3AA_1_0.woff2') format('woff2'),url('../assets/webfonts/3AA3AA_1_0.woff') format('woff'),url('../assets/webfonts/3AA3AA_1_0.ttf') format('truetype');}
@font-face {font-family: 'Gilroy'; font-weight: 700; src: url('../assets/webfonts/3AA3AA_2_0.eot');src: url('../assets/webfonts/3AA3AA_2_0.eot?#iefix') format('embedded-opentype'),url('../assets/webfonts/3AA3AA_2_0.woff2') format('woff2'),url('../assets/webfonts/3AA3AA_2_0.woff') format('woff'),url('../assets/webfonts/3AA3AA_2_0.ttf') format('truetype');}

html body {
	font-family: 'Gilroy', sans-serif;
}

html {
	background: #1c1c1c;
	scroll-behavior: smooth;
}

body {
	background: #fff;
}

.logo-wrap {
	position: fixed;
	height: 100vh;
	width: 100%;
	top: 0;
	left: 0;
	background: #fff;

	nav {
		position: absolute;
		top: 20px;
		left: 50%;
		z-index: 99;
		transform: translateX(-50%);
		display: flex;
		justify-content: center;
		opacity: 0;
		transition: .25s all;

		&.show {
			opacity: 1;
		}

		a {
			color: #212529;
			display: block;
			margin: 0 20px;
		}
	}

	.arrow {
		position: absolute;
		bottom: 25px;
		left: 50%;
		transform: translateX(-50%) translateY(200%);
		max-width: 35px;
		opacity: 0;
		transition: .5s opacity, .25s transform;

		&.show {
			transform: translateX(-50%) translateY(0);
			opacity: 1;
		}
	}

	img {
		max-width: 50%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: none;

		@media(max-width: 992px){
		    display: block;
		}
	}
}

.logo {
	font-size: 100px;
	width: 800px;
	height: 100vh;
	display: flex;
	align-items: center;
	letter-spacing: -4.37px;
	position: absolute;
	left: 50%;
	transform: translateX(-286px);

	@media(max-width: 992px){
	    display: none;
	}

	.typed-cursor {
		font-weight: 400;
		position: relative;
		top: -10px;
		animation: typedjsBlink 1s infinite !important;
	}
}

body {
	h2 {
		font-weight: 600;
		font-size: 50px;
	}

	h2, h3 {
		width: 100%;
		margin-bottom: 40px;
		text-transform: uppercase;

		@media(max-width: 992px){
		    text-align: center
		}
	}

	h3 {
		margin-bottom: 15px;
		font-size: 25px;

		@media(max-width: 992px){
		    padding: 0;
		}
	}
}

.work-wrap {
	background: #f3f3f3;
	padding: 80px 0;
	margin-top: 100vh;
	position: relative;
	z-index: 2;

	.container {
		margin-bottom: 50px;
	}
}

.container {
	position: relative;
}

h3 {
	position: absolute;
	right: 100%;
	top: 0;
	width: auto;
	text-align: right;
	padding-right: 40px;
	display: inline-block;

	@media(max-width: 1560px){
		position: static;
		text-align: left;
	}
}

.work {
	display: flex;
	flex-wrap: wrap;

	.item {
		width: 30%;
		margin-bottom: 3%;
		margin-right: 4.5%;
		padding: 15px;
		border-radius: 5px;
		cursor: pointer;
		background: #fff;
		transition: .25s all;

		&:nth-child(3n){
			margin-right: 0;
		}

		@media(max-width: 992px){
		    width: 100%;
			margin-bottom: 5%;
			margin-right: 0;

			&:nth-child(2n){
				margin-right: 0;
			}
		}

		&:hover {
			box-shadow: 0 0 15px rgba(#000, .1);
			text-decoration: none;
		}

		img {
			max-width: 100%;
			margin-bottom: 10px;
		}

		p {
			padding: 10px 5px 5px 5px;
			line-height: 1.4;
			margin: 0;
			color: #554d56;
			font-size: 13px;
			font-weight: 500;
			text-transform: uppercase;
			transition: .15s all;
		}

		&:hover {
			p {
				color: #1c1c1c;
			}
		}
	}
}

.footer {
	background: #1c1c1c;
	padding: 40px 0 80px;
	position: relative;
	z-index: 2;

	.container {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;

		@media(max-width: 992px){
		    display: block;

			p {
				text-align: left;
				margin-top: 20px;
			}
		}
	}

	svg {
		display: block;
		max-width: 100px;
	}

	p {
		color: #fff;
		margin: 0;
		font-size: 12px;
		text-align: right;
	}
}

.btn {

	&.btn-primary {
		background: #000;
		color: #fff;
		font-weight: 600;
		text-transform: uppercase;
		font-size: 18px;
		border: 1px solid #000;
		transition: .25s all;
		padding: 10px 20px 8px 20px;

		&:hover {
			background: none;
			color: #000;
			box-shadow: 0 0 15px rgba(#000, .1);
		}
	}
}

.about {
	padding: 80px 0;
	background: #fff;
	position: relative;
	z-index: 2;

	.columns {
		column-count: 2;
		column-gap: 30px;

		@media(max-width: 992px){
		    column-count: 1;
		}
	}

	.btn {
		margin-top: 30px;
	}
}